import React from 'react'
import moment from 'moment';
import Clock from 'react-live-clock';
import SailRotationDiagram from '../../components/sailRotationDiagram/sailRotationDiagram';
import './LiveSailRotationSensorPanel.css';
import WindDirectionArrow from '../../components/weatherForecastTable/windDirectionArrow';
import UnitsContext from '../../UnitsContext';

class LiveSailRotationSensorPanel extends React.Component {

    static contextType = UnitsContext;

    getBrakeIndicationColour(brakeOn) {

        if (brakeOn) {
            return ("#00b000");
        }
        else {
            return ("#d69a00");
        }
    }

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span></span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var brakeIndicationColor = this.getBrakeIndicationColour(this.props.molen.latestSailRotationReading.brakeOn);

        /// display wind speed in local units, defaulting to British
        var unitsContext = this.context;
        var speedUnitText = "mph";
        var speedMultiplier = 2.23694;
        var sailRotationText = "rpm";
        var sailRotationMultiplier = 1;
        if (unitsContext === "metric") {
            speedUnitText = "km/h";
            speedMultiplier = 3.6;
            sailRotationText = "ends/min";
            sailRotationMultiplier = 4; // TODO - make this work for mills other than 4 sailed
        }


        return (

            <div className="panel-narrow">
                <div className="panel-header">
                    <div className="panel-top-left">
                        LIVE SAIL ROTATION
                    </div>
                    <div className="panel-top-right">
                        <Clock format={'HH:mm:ss'}
                            ticking={true}
                        />
                    </div>
                </div>
                <div className="panel-body">
                    <div className="sail-rotation-diagram-panel">
                        <SailRotationDiagram molen={this.props.molen} />
                        <div className="sail-rpm-panel">
                            <div className="app-panel-header-text"><br /></div>
                            <div className="app-oversize-text">{Math.round((this.props.molen.latestSailRotationReading.currentSpeedRpm) * sailRotationMultiplier * 10) / 10}</div><br />{sailRotationText}<br /><br />
                            <div className="app-supersmalltext"><br />
                            </div>
                            {(this.props.molen.latestSailRotationReading.sailSpanMetres != null && this.props.molen.latestSailRotationReading.sailSpanMetres > 0) ?

                                <div className="app-standard-text">
                                    <b>{Math.round((this.props.molen.latestSailRotationReading.sailSpanMetres * 3.1416 * speedMultiplier * this.props.molen.latestSailRotationReading.currentSpeedRpm) / 60)} {speedUnitText}</b><br />
                                    sail tip speed
                                </div>
                                :
                                <div className="app-standard-text">
                                    <b>Wind</b><br />
                                    <div className="sail-wind-panel">
                                        <div className="sail-wind-left-box">
                                            <WindDirectionArrow windDirection={this.props.molen.latestWeatherObservation.windDirection}></WindDirectionArrow>
                                        </div>
                                        <div className="sail-wind-right-box">
                                            <div className="sail-wind-direction">
                                            {this.props.molen.latestWeatherObservation.windDirectionCompassPoint}</div>
                                            {Math.round(this.props.molen.latestWeatherObservation.windSpeedMs * speedMultiplier)} {speedUnitText}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>

                    {this.props.molen.latestSailRotationReading.brakeLeverSensorFitted &&
                        <>
                            <div className="brake-status-panel" style={{ backgroundColor: brakeIndicationColor }}>
                                {this.props.molen.latestSailRotationReading.brakeOn ? <>BRAKE ON</> : <span class="blink">BRAKE OFF</span>}
                            </div>
                        </>
                    }

                    <div className="app-supersmalltext"><br />
                    </div>
                </div>


                <div className="panel-header">
                    <div className="panel-top-left">
                        TOTAL SAIL REVOLUTIONS
                    </div>
                </div>

                <div className="panel-body">
                    <div className="sail-turns-panel-contents">
                        <div>
                            <table id="sail-turns-table">
                                <tbody>
                                    <tr>
                                        <td width="36%" align="right"><div className="sail-table-large-numbers">{this.props.molen.latestSailRotationReading.revCountToday.toLocaleString('en-US')}</div></td>
                                        <td><div className="sail-table-major-text">today</div></td>
                                    </tr>
                                    <tr>
                                        <td align="right"><div className="sail-table-large-numbers" align="center">{this.props.molen.latestSailRotationReading.revCountThisMonth.toLocaleString('en-US')}</div></td>
                                        <td><div className="sail-table-major-text">this month</div></td>
                                    </tr>
                                    <tr>
                                        <td align="right"><div className="sail-table-large-numbers" align="center">{this.props.molen.latestSailRotationReading.revCountThisYear.toLocaleString('en-US')}</div></td>
                                        <td><div className="sail-table-major-text">this year</div></td>
                                    </tr>
                                    <tr height="50px">
                                        <td align="right"><div className="sail-table-large-numbers" align="center">{this.props.molen.latestSailRotationReading.totalRevCount.toLocaleString('en-US')}</div></td>
                                        <td><div className="sail-table-major-text">since recording started<br />({this.props.molen.latestSailRotationReading.revCountSinceDesc != null ? this.props.molen.latestSailRotationReading.revCountSinceDesc : moment(this.props.molen.latestSailRotationReading.revCountSinceDate).format("MMMM YYYY")})</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="app-supersmalltext"><br />
                        Reported {moment(this.props.molen.latestSailRotationReading.utcTime).format("HH:mm:ss")} by sail speed sensor
                    </div>


                </div>
            </div>
        )
    }
}

export default LiveSailRotationSensorPanel
