import React from 'react';
import './windWarningsBar.css';
import WindLogic from '../windLogic';
import moment from 'moment';

class WindWarningsBar extends React.Component {

    componentDidMount() {
    }

    render() {

        var molen = this.props.molen;

        var windWarningTriangleIcon = "";
        var windWarningTriangleCaption = "";
        var windWarningSeverity = 0;
        var windWarningDirectionText = "";
        var windWarningTimeText = "";
        var windWarningSummaryText = "";
        var windWarningColour = "#FF0000";

        if (molen.windWarnings.length > 0) {
            windWarningSeverity = Math.max(molen.windWarnings[0].strongWindWarningLevel, molen.windWarnings[0].tailWindWarningLevel);
            if (windWarningSeverity >= 1) { windWarningColour = "#E0A000"; windWarningTriangleIcon = "img/wind_warning_yellow_200px.png"; windWarningTriangleCaption = "Wind Warning Triangle - Red"; }
            if (windWarningSeverity >= 2) { windWarningColour = "#E06000"; windWarningTriangleIcon = "img/wind_warning_orange_200px.png"; windWarningTriangleCaption = "Wind Warning Triangle - Orange"; }
            if (windWarningSeverity >= 3) { windWarningColour = "#F00000"; windWarningTriangleIcon = "img/wind_warning_red_200px.png"; windWarningTriangleCaption = "Wind Warning Triangle - Yellow"; }

            windWarningDirectionText = molen.windWarnings[0].windDirectionEightPointCompassLow;
            if (molen.windWarnings[0].windDirectionEightPointCompassLow !== molen.windWarnings[0].windDirectionEightPointCompassHigh) {
                windWarningDirectionText = molen.windWarnings[0].windDirectionEightPointCompassLow + " to " + molen.windWarnings[0].windDirectionEightPointCompassHigh;
            }

            if (this.props.molen.windWarnings[0].currentOrForecast === "CURRENT") {
                windWarningTimeText = (this.props.molen.windWarnings[0].endTimeUtc !== "0001-01-01T00:00:00") ? "Until " + moment(this.props.molen.windWarnings[0].endTimeUtc).format("ddd HHmm") : "Until further notice";
                windWarningSummaryText = (this.props.molen.windWarnings[0].tailWindWarningLevel > 0) ? "TAIL WIND" : "STRONG WIND";
            }
            else {
                windWarningTimeText = (this.props.molen.windWarnings[0].endTimeUtc !== "0001-01-01T00:00:00") ? moment(this.props.molen.windWarnings[0].startTimeUtc).format("HHmm ddd DD MMM") + " - " + moment(this.props.molen.windWarnings[0].endTimeUtc).format("HHmm ddd DD MMM") : "From " + moment(this.props.molen.windWarnings[0].startTimeUtc).format("HHmm ddd DD MMM");
                windWarningSummaryText = (this.props.molen.windWarnings[0].tailWindWarningLevel > 0) ? "TAIL WIND FORECAST" : "STRONG WIND FORECAST";
            }
        }

        return (
            <>

                <div className="wind-warnings-container" style={{"borderColor" : windWarningColour}}>
                    <div className="wind-warning-left">
                        <img className="wind-warning-icon" src={windWarningTriangleIcon} style={{ "verticalAlign": "top" }} title={"Wind Warning"} alt={windWarningTriangleCaption}></img>
                    </div>
                    <div className="wind-warning-right">
                        <b>{windWarningSummaryText}</b><br />
                        {windWarningTimeText}<br />
                        {new WindLogic().isWindGusting(this.props.molen.windWarnings[0].maxWindSpeedMs, this.props.molen.windWarnings[0].maxWindGustMs) ?
                            <div className="wind-warning-text-color-reverse">Gusts to {Math.round(this.props.molen.windWarnings[0].maxWindGustMs * 2.237)} mph {windWarningDirectionText}</div> :
                            <>{Math.round(this.props.molen.windWarnings[0].maxWindSpeedMs * 2.237)} mph {windWarningDirectionText}</>}
                        <div className="wind-warning-warning-text-red">{molen.windWarnings != null & molen.windWarnings.length > 1 ? "+ further warnings" : ""}</div>
                    </div>
                </div>



            </>
        )
    }
}



export default WindWarningsBar